import crudActions from '@/libs/storeConstActions'
import axios from '@/libs/axios'
import store from '@/store'
import { stringify } from 'qs'

const endpoint = () => `inventory/${store.state.inventories.inventoryId}/inventory-items`
const suppliersEndpoint = () => 'customers'
const catalogStock = () => '/catalog/stocks'
const warehousesEndpoint = () => '/settings/warehouses'
const inventoryItemDamagedEndpoint = () => 'inventory-item/damaged'
const Actions = crudActions(endpoint)
const importCSV = (ctx, data) => axios.post('inventory-items/csv-import', data.formData)
const getListAddToExistingQuote = (ctx, data) => axios.get(`orders/rental/add-to-existing-quote/${data.product}`)
const addToExistingQuote = (ctx, data) => axios.post('orders/rental/add-to-existing-quote', data)
const printAssetsCSV = (ctx, data) => axios.get(`/inventory-items/print-assets?${data}`)

const getStockProducts = ({ commit }, queryParams) => new Promise((resolve, reject) => {
  axios.get(catalogStock(), {
    params: queryParams,
    paramsSerializer: params => stringify(params),
  })
    .then(response => {
      commit('STOCK_PRODUCTS_LIST', response.data.data.data)
      resolve(response)
    })
    .catch(error => {
      reject(error)
    })
})

const getInventoryAudit = ({ commit }, queryParams) => new Promise((resolve, reject) => {
  axios.get('inventory/get-audits', {
    params: queryParams,
  })
    .then(response => {
      commit('SET_INVENTORY_AUDIT', response.data.data.data)
      resolve(response)
    })
    .catch(error => {
      console.log(error)
      reject(error)
    })
})

const getInventoryCatalogAudit = (_, queryParams) => {
  const url = queryParams.inv_id ? `inventory/${queryParams.id}/get-audit` : `products/${queryParams.id}/audits`
  const params = {
    inventory_item_id: queryParams.inv_id,
    order_column: queryParams.order_column,
    order_type: queryParams.order_type,
    per_page: queryParams.per_page,
    page: queryParams.page,
  }
  return axios.get(url, {
    params,
  })
}

const getInventoriesList = ({ commit }, queryParams) => new Promise((resolve, reject) => {
  axios.get('inventories/list', {
    params: queryParams,
    paramsSerializer: params => stringify(params),
  })
    .then(response => {
      commit('SET_INVENTORIES_LIST', response.data.data.data)
      resolve(response)
    })
    .catch(error => {
      console.log(error)
      reject(error)
    })
})
const getOrderPurchasesList = () => axios.get('/orders/purchases')
const getInventoriesSubList = ({ commit }, queryParams) => new Promise((resolve, reject) => {
  axios.get(`inventory/${store.state.inventories.inventoryId}/inventory-items`, {
    params: queryParams,
    paramsSerializer: params => stringify(params),
  })
    .then(response => {
      const resData = response.data.data.data
      commit('LIST', resData)
      resolve(response)
    })
    .catch(error => {
      console.log(error)
      reject(error)
    })
})

const getInventoryGeneratedBarCode = (ctx, queryParams) => new Promise((resolve, reject) => {
  // axios.get(`/inventory-items/print-assets?${url}`)
  axios.get('/inventory-items/print-assets', {
    params: queryParams,
    paramsSerializer: params => stringify(params),
  })
    .then(response => {
      resolve(response)
    })
    .catch(error => {
      console.log(error)
      reject(error)
    })
})
const getStockProduct = (ctx, { id }) => axios.get(`products/${id}`)

const getSuppliers = ({ commit }, queryParams) => new Promise((resolve, reject) => {
  axios.get(suppliersEndpoint(), {
    params: queryParams,
    paramsSerializer: params => stringify(params),
  })
    .then(response => {
      resolve(response)
    })
    .catch(error => {
      console.log(error)
      reject(error)
    })
})
const getStockProductsForAutoSuggest = () => axios.get(`${catalogStock()}`)
const getWarehouses = (ctx, { id }) => axios.get(`${warehousesEndpoint()}/${id}`)
const updateInventory = (ctx, data) => axios.post(`/${endpoint()}/${data.get('id')}?_method=PUT`, data)
const damageInventory = (ctx, data) => axios.post(`/${inventoryItemDamagedEndpoint()}`, data)
const ordersDamagedReport = (ctx, data) => axios.post('/orders/damaged-report', data)
const deleteAttachment = (ctx, data) => axios.delete(`/attachments/${data.id}`)
const generateInventoryItemBarcode = (ctx, id) => axios.get(`/inventory_items/${id}/generate/barcode`)

export default {
  ...Actions,
  getSuppliers,
  getOrderPurchasesList,
  importCSV,
  getListAddToExistingQuote,
  printAssetsCSV,
  damageInventory,
  getInventoriesList,
  getStockProduct,
  getWarehouses,
  getInventoryGeneratedBarCode,
  getInventoriesSubList,
  updateInventory,
  getStockProducts,
  getInventoryAudit,
  generateInventoryItemBarcode,
  getStockProductsForAutoSuggest,
  deleteAttachment,
  getInventoryCatalogAudit,
  addToExistingQuote,
  ordersDamagedReport,
}
