<template>
  <router-view />
</template>

<script>
import { onUnmounted } from '@vue/composition-api'
import store from '@/store'
import inventoryModule from '@/store/main/inventory'
import calendar from '@/store/calendar'

export default {
  setup() {
    const MODULE_NAME = 'inventories'
    const CALENDAR_MODULE_NAME = 'calendar'
    if (!store.hasModule(CALENDAR_MODULE_NAME)) store.registerModule(CALENDAR_MODULE_NAME, calendar)
    if (!store.hasModule(MODULE_NAME)) store.registerModule(MODULE_NAME, inventoryModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(MODULE_NAME)) store.unregisterModule(MODULE_NAME)
    })

    return {
      MODULE_NAME,
    }
  },
}
</script>

<style scoped>

</style>
